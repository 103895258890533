<template>
  <div>
     
    <personal-information  />
    <hr class="border-2"  /> 
    <company-information />

  </div>
</template>
<script>
//components
import PersonalInformation from './PersonalInformation.vue'
import CompanyInformation from './CompanyInformation.vue'
//vuex
import { mapActions } from 'vuex'
export default {
    data(){return{
      // isBusy:false,
    }},  
    
    components:{
      PersonalInformation,
      CompanyInformation,
      
    },
    
    mounted(){
          
    },
}
</script>